import { Error } from "../../common/payloads";

// Additional params to keep a consistent option bag approach.
type AdditionalFetchParams = {
  url: string;
  data?: { [key: string]: any };
  isRedirect?: boolean;
};

// Type definition for return values
export type Payload<T> = {
  status: number;
  data: T | Error;
};

/**
 * Wrapper around the Fetch browser API.
 * @param {RequestInit & AdditionalFetchParams} init Request object with additional params for convenience.
 * @returns {Promise<Payload<T>>} Object of generic type from URL endpoint.
 */
export const executePayloadRequest = async <T>(
  request: RequestInit & AdditionalFetchParams
): Promise<Payload<T>> => {
  let data: T;
  let error: Error;
  let payload: Payload<T>;

  try {
    const res: Response = await fetch(request.url, {
      // Default values for request.
      body: request.data ? JSON.stringify(request.data) : undefined,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      // Overwrite any defaults in option bag.
      ...request,
    });

    if (request.isRedirect) {
      return;
    } else {
      const status = await res.status;

      if (res.status === 200) {
        data = (await res.json()) as T;
        payload = { status, data };
      } else {
        error = (await res.json()) as Error;
        payload = { status, data: error };
      }

      return payload;
    }
  } catch (e) {
    console.log("error executing payload request " + e);
    throw e;
  }
};
