import React, { Fragment } from "react";

import { UpgradeHeader } from "../upgradeHeader";
import pluralize from "pluralize";

type CompleteUpgradeProps = {
  displayCompleteUpgrade: string;
  totalTicketQuantity: number;
};

export const CompleteUpgrade: React.FC<CompleteUpgradeProps> = ({
  displayCompleteUpgrade,
  totalTicketQuantity,
}: CompleteUpgradeProps) => {
  const title = "Thank you!";

  return (
    <Fragment>
      <div
        className="complete-upgrade"
        style={{
          display: `${displayCompleteUpgrade}`, // set display to none when !isCompleteUpgrade
        }}
      >
        <UpgradeHeader title={title} />
        <div className="complete-upgrade__message">
          <h2>
            Your {pluralize("ticket", totalTicketQuantity, false)}{" "}
            {pluralize("has", totalTicketQuantity, false)} been updated to
            include a tour! You will also receive an email with confirmation of
            your order.
          </h2>
          <h3>
            Click below to view your{" "}
            {pluralize("ticket", totalTicketQuantity, false)}, and enjoy your
            visit to the Barnes!
          </h3>
        </div>
      </div>
    </Fragment>
  );
};
