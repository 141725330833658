import {
  OrderWithRedirect,
  PaymentInformation,
  OrderLink,
} from "../../common/payloads";
import { executePayloadRequest, Payload } from "./httpService";

/**
 * Get order payload from i query string.
 * @param {string} i encoded query string param.
 * @returns {OrderPayload} - Membership details object associated with membershipId.
 * @returns {EventTimeObject[]} - Array of tours beginning within one hour of the admission time. Defaults to empty array.
 * @returns {string} - Optional redirect link for member tickets. Defaults to undefined.
 * @throws - @see httpService
 */
export const getOrder = async (
  i: string
): Promise<Payload<OrderWithRedirect>> =>
  executePayloadRequest<OrderWithRedirect>({
    url: `/api/orders?i=${i}`,
    method: "GET",
  });

export const getOrderLink = async (id: string): Promise<Payload<OrderLink>> => {
  return executePayloadRequest<OrderLink>({
    url: `/api/orders/link`,
    method: "POST",
    data: { id },
  });
};

export const rebookOrder = async (
  orderId: string,
  tourId: string,
  checkoutInput: PaymentInformation,
  acmeRequestId: string
): Promise<Payload<OrderWithRedirect>> => {
  const body = {
    orderId,
    tourId,
    checkoutInput,
    acmeRequestId,
  };

  return executePayloadRequest<OrderWithRedirect>({
    url: `api/orders/rebook`,
    method: "POST",
    body: JSON.stringify(body),
  });
};
