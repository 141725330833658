import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { EventTimeObject } from "acme-ticketing-client";
import pluralize from "pluralize";

import { BannerHeader } from "./bannerHeader";
import { SpinnerSuspense } from "./spinner";
import { Upgrade } from "../upgrade/upgrade";
import { getTours } from "../../services/tourService";
import { DisplayContext } from "../../contexts/displayContext";
import { OrderContext } from "../../contexts/orderContext";
import { ToursContext } from "../../contexts/toursContext";
import { useDataLayer } from "../../hooks/useDataLayer";
import { useTime } from "../../hooks/useTime";
import { Error } from "../../../common/payloads";
import { formatMinutes } from "../../util/formatting";
import { getGTMData } from "../../util/gtmDataLayerHelper";
import {
  getParameterByName,
  getTicketQuantity,
  getTimeDiffInMin,
  getUpgradeCost,
} from "../../util/helpers";

const Background =
  "https://barnes-assets.s3.amazonaws.com/SMS-ticketing-site/rousseau.jpg";

type HeroBannerProps = {
  header?: string;
  src?: string;
  alt?: string;
  className?: string;
  // imageOffset?: number;
};

export const HeroBanner: React.FC<HeroBannerProps> = ({
  header,
  alt,
  className,
}: // imageOffset = 0,
HeroBannerProps) => {
  const currentTime = useTime(60000); // Refresh is set to 60000ms (1 min)
  const checkTourInterval = useTime(300000); // Refresh is set to 500000ms (5 min)
  const updateDataLayer = useDataLayer();
  const { selectedTour, setSelectedTour, setTours } = useContext(ToursContext);
  const { showUpgrade, setShowUpgrade, tourUpgradeComplete } =
    useContext(DisplayContext);
  const { eventItem, order } = useContext(OrderContext);
  const imageWrapperRef = useRef<HTMLDivElement>();
  // const [translateY, setTranslateY] = useState(imageOffset);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [timeToTour, setTimeToTour] = useState(null);
  const [upgradeAvailable, setUpgradeAvailable] = useState(
    selectedTour && !tourUpgradeComplete
  );
  const [ticketQuantity, setTicketQuantity] = useState(
    eventItem ? getTicketQuantity(eventItem) : 0
  );
  const [updateGTM, setUpdateGTM] = useState(false);
  const [firstBannerClick, setFirstBannerClick] = useState(true);

  /**
   * Helper for getting upgradeAvailable state.
   * Prevents issue of useEffect being triggered every time upgradeAvailable changes.
   */
  const getUpgradeAvailable = useCallback(() => {
    return upgradeAvailable;
  }, [upgradeAvailable]);

  const refreshTours = useCallback(async () => {
    try {
      const startTime = eventItem.eventDate;
      const i = getParameterByName("i", window.location.href);
      const { status, data } = await getTours(startTime, ticketQuantity, i);

      if (status === 200) {
        const refreshedTours = data as EventTimeObject[];
        setTours(refreshedTours);
        setSelectedTour(refreshedTours[0]);
        return refreshedTours;
      } else {
        const error = data as Error;
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }
  }, [eventItem, setSelectedTour, setTours, ticketQuantity]);

  let height = "24vh";
  let display = "inline";
  let headerText = upgradeAvailable
    ? `Dive deeper into the Barnes collection! Upgrade your ${pluralize(
        "ticket",
        ticketQuantity,
        false
      )} and join a tour starting in ${formatMinutes(timeToTour)}.`
    : "Welcome to the Barnes! Please find your tickets below. Enjoy your visit!";
  let bottomText = "Learn More";

  useEffect(() => {
    setTicketQuantity(eventItem && getTicketQuantity(eventItem));
  }, [setTicketQuantity, eventItem]);

  // Updates the timeToTour and upgradeAvailable every minute
  useEffect(() => {
    if (!tourUpgradeComplete && selectedTour) {
      const minToTour = getTimeDiffInMin(currentTime, selectedTour.startTime);
      setUpgradeAvailable(minToTour <= 60 && minToTour > 0);
      setTimeToTour(minToTour);
    } else {
      setUpgradeAvailable(false);
    }
  }, [
    currentTime,
    setUpgradeAvailable,
    setTimeToTour,
    selectedTour,
    tourUpgradeComplete,
  ]);

  // When it is past the selectedTour startTime, refresh tour list
  useEffect(() => {
    const fetchTours = async () => {
      await refreshTours();
    };

    if (timeToTour <= 0) {
      fetchTours();
    }
  }, [timeToTour, refreshTours]);

  // Fetches tour info every 5 minutes, checks available capacity, and updates selectedTour
  useEffect(() => {
    const fetchTours = async () => {
      await refreshTours();
    };

    if (getUpgradeAvailable()) {
      fetchTours();
      setUpdateGTM(true);
    }
  }, [checkTourInterval, refreshTours, getUpgradeAvailable, setUpdateGTM]);

  // Sends information to GTM data layer when tour upgrade offer is displayed
  useEffect(() => {
    if (
      upgradeAvailable &&
      updateGTM &&
      order.eventItems[0].eventName.includes("Admission")
    ) {
      updateDataLayer(getGTMData("impression", order, eventItem, selectedTour));
      setUpdateGTM(false);
    }
  }, [
    upgradeAvailable,
    order,
    eventItem,
    selectedTour,
    updateGTM,
    setUpdateGTM,
    updateDataLayer,
  ]);

  let heroBannerClassName = "hero-banner";
  let overlayClassName = "hero-banner__image-overlay";

  if (isError) {
    heroBannerClassName = `${heroBannerClassName} hero-banner--no-image`;
  }

  if (isLoaded) {
    overlayClassName = `${overlayClassName} hero-banner__image-overlay--loaded`;
  }

  if (showUpgrade) {
    height = "90vh";
    display = "none";
    // headerText = "Make the most of your visit to the Barnes.";
    // bottomText = "No Thanks";
  }

  const showTourUpgrade = async () => {
    setShowUpgrade(!showUpgrade);

    if (firstBannerClick) {
      const upgradeCost = getUpgradeCost(
        eventItem,
        selectedTour.event.priceList.prices[0].price
      );
      updateDataLayer(getGTMData("detail", order, eventItem, selectedTour));
      setFirstBannerClick(false);
    }
    // Add gtm stuff here for clicking on the banner
  };

  return (
    <div className={`${heroBannerClassName} ${className || ""}`}>
      <div className="hero-banner__header-wrapper">
        <SpinnerSuspense className="hero-banner__spinner" isLoaded={isLoaded}>
          {upgradeAvailable ? (
            <div className="upgrade">
              <a
                type="button"
                onClick={showTourUpgrade}
                className="banner-header"
                style={{
                  display: `${display}`, // set display to none when showUpgrade
                }}
              >
                <BannerHeader
                  imageWrapperHeight={
                    imageWrapperRef.current
                      ? imageWrapperRef.current.offsetHeight
                      : null
                  }
                  header={headerText}
                  bottomText={bottomText}
                />
              </a>
              {showUpgrade && (
                <Upgrade timeToTour={timeToTour} refreshTours={refreshTours} />
              )}
            </div>
          ) : (
            <BannerHeader
              imageWrapperHeight={
                imageWrapperRef.current
                  ? imageWrapperRef.current.offsetHeight
                  : null
              }
              header={headerText}
            />
          )}
        </SpinnerSuspense>
      </div>
      <div
        className="hero-banner__image-wrapper"
        ref={imageWrapperRef}
        style={{
          height: `${height}`, // set to height 100% when showUpgrade
        }}
      >
        <img
          className="hero-banner__image"
          src={Background}
          alt={alt || header}
          style={{
            // transform: `translateY(-${translateY}px)`,
            opacity: isLoaded && !isError ? 1 : 0,
          }}
          // Wrap in STO to insure load animation fires.
          onLoad={() => setTimeout(() => setIsLoaded(true), 0)}
          onError={() => {
            setIsLoaded(true);
            setIsError(true);
          }}
        />
        <div className={overlayClassName}></div>
      </div>
    </div>
  );
};
