import {
  OrderPayload,
  EventItem,
  EventTimeObject,
} from "acme-ticketing-client";

import { getOrderTotal, getTicketQuantity, getUpgradeCost } from "./helpers";

interface TicketInformation {
  type: string;
  id: string;
  quantity: number;
  price: string;
  total: string;
}

interface OrderInformation {
  orderId: string;
  total: number;
  tickets: TicketInformation[];
}

interface GTMData {
  event: string;
  orderInformation: OrderInformation;
  ecommerce: {
    [eventActions: string]: {
      actionField: {
        id: string;
        revenue: number;
        orderInformation: OrderInformation;
      };
      products: {
        name: string;
        id: string;
        startTime: string;
        upgradeTotal: number;
        price: number;
        quantity: number;
      }[];
    };
  };
}

/**
 * Formats ticket data given an event
 * @param {EventItem} eventItem - event information
 * @returns {TicketInformation[]}
 */
const getTicketData = (eventItem: EventItem): TicketInformation[] => {
  return eventItem.items.map((item) => ({
    type: item.ticketTypeName,
    id: item.ticketTypeId,
    quantity: item.quantity,
    price: item.discountedUnitPrice,
    total: item.discountedSubtotal,
  }));
};

/**
 * Returns data structured for the GTM data layer
 * @param {string} event - GTM event type
 * @param {OrderPayload} order - order information
 * @param {EventItem} eventItem - event for original tickets
 * @param {EventTimeObject} tour - event for upgrade
 * @returns {GTMData} object formatted for GTM data layer
 */
export const getGTMData = (
  event: string,
  order: OrderPayload,
  eventItem: EventItem,
  tour: EventTimeObject
): GTMData => {
  const tickets = getTicketData(eventItem);
  const total = getOrderTotal(eventItem);
  const upgradeTotal = getUpgradeCost(
    eventItem,
    tour.event.priceList.prices[0].price
  );

  const actions = {
    impression: "impressions",
    detail: "detail",
    addToCart: "add",
    checkout: "checkout",
    purchase: "purchase",
  };

  const action = actions[event];
  const step = event === "checkout" ? { step: 1 } : {};
  const orderInformation = {
    orderId: order.orderId,
    total,
    tickets,
  };

  return {
    event,
    orderInformation,
    ecommerce: {
      [action]: {
        actionField: {
          id: order.orderId,
          revenue: upgradeTotal,
          orderInformation,
          ...step,
        },
        products: [
          {
            name: tour.event.name,
            id: tour.event.id,
            startTime: tour.event.startTime,
            upgradeTotal,
            price: tour.event.priceList.prices[0].price,
            quantity: getTicketQuantity(eventItem),
          },
        ],
      },
    },
  };
};
