import React, { createContext } from "react";
import { EventTimeObject } from "acme-ticketing-client";

// Information for available tours and selected tour

export type ToursType = {
  tours: EventTimeObject[]; // List of all available tours starting at the ticket admission time until EOD
  setTours: React.Dispatch<React.SetStateAction<EventTimeObject[]>>;
  selectedTour: EventTimeObject | null; // The tour that will be displayed for the upgrade
  setSelectedTour: React.Dispatch<React.SetStateAction<EventTimeObject | null>>;
};

export const ToursContext = createContext<ToursType>({
  tours: null,
  setTours: null,
  selectedTour: null,
  setSelectedTour: null,
});
