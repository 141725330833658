import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import pluralize from "pluralize";

import { CompleteUpgrade } from "./pages/completeUpgrade";
import { PaymentDetails } from "./pages/paymentDetails";
import { TourUpgrade } from "./pages/tourUpgrade";
import { Error as ErrorComponent } from "../shared/error";
import { SpinnerSuspense } from "../shared/spinner";
import { DisplayContext } from "../../contexts/displayContext";
import { OrderContext } from "../../contexts/orderContext";
import { ToursContext } from "../../contexts/toursContext";
import { rebookOrder } from "../../services/orderService";
import { useCreditCardForm } from "./hooks/useCreditCardForm";
import { useDataLayer } from "../../hooks/useDataLayer";
import { getGTMData } from "../../util/gtmDataLayerHelper";
import {
  getOrderTotal,
  getTicketQuantity,
  getUpgradeCost,
} from "../../util/helpers";
import { Error, OrderWithRedirect } from "../../../common/payloads";

type UpgradeProps = {
  timeToTour: number;
  refreshTours: () => void;
};

export const Upgrade: React.FC<UpgradeProps> = ({
  timeToTour,
  refreshTours,
}: UpgradeProps) => {
  const { order, eventItem, setOrder, setEventItem } = useContext(OrderContext);
  const { tours, selectedTour } = useContext(ToursContext);
  const { setShowUpgrade, setTourUpgradeComplete } = useContext(DisplayContext);

  const ref = useRef<HTMLDivElement>();

  const creditCardInputs = useCreditCardForm();
  const { number, expDate, cvc, validateCreditCard } = creditCardInputs;
  const updateDataLayer = useDataLayer();

  // State to manage which page is shown
  const [isTourUpgrade, setIsTourUpgrade] = useState(true);
  const [isPaymentDetails, setIsPaymentDetails] = useState(false);
  const [isCompleteUpgrade, setIsCompleteUpgrade] = useState(false);

  // State to manage visual elements
  const [isResizeFont, setIsResizeFont] = useState(false);
  const [isUpgradeError, setIsUpgradeError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [disableContinue, setDisableContinue] = useState(false);
  const [paymentError, setPaymentError] = useState(undefined);

  // Unique id to be passed in header to ACME to prevent dupe charges
  const [acmeRequestUuid, setAcmeRequestUuid] = useState(uuidv4());

  const [errorMessage, setErrorMessage] = useState(
    <Fragment>
      <p className="upgrade__error-text">
        Oops! We&apos;re having trouble completing your request.
      </p>
      <p className="upgrade__error-text">
        Please contact <a href="tel:2152787200">215-278-7200</a> for assistance.
      </p>
    </Fragment>
  );

  let displayTourUpgrade = "none";
  let displayPaymentDetails = "none";
  let displayCompleteUpgrade = "none";
  let title;
  let subtitle;
  let backText;
  let continueText;

  // Set is loaded after first render.
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // On mount, see if text fits in container, if not then setState to true.
  useEffect(() => {
    if (isLoaded) {
      // If text is bigger than it's current boundary
      const isResized = ref.current && isLoaded;

      setIsResizeFont(isResized);
    }
  }, [isLoaded]);

  let headerStyle: React.CSSProperties = {};
  if (isResizeFont) {
    headerStyle = {
      ...headerStyle,
      fontSize: "27.5px",
    };
  }

  const totalTicketQuantity = getTicketQuantity(eventItem);
  let tourTotal =
    totalTicketQuantity * tours[0].event.priceList.prices[0].price;
  const totalDue = getUpgradeCost(
    eventItem,
    tours[0].event.priceList.prices[0].price
  );

  if (isTourUpgrade) {
    displayTourUpgrade = "inline";
    backText = "No thanks";
    continueText = "Sign me up!";
  }

  if (isPaymentDetails) {
    displayPaymentDetails = "inline";
    backText = "I've changed my mind";
    continueText = "Complete Upgrade";
  }

  if (isCompleteUpgrade) {
    displayCompleteUpgrade = "inline";
    backText = "Close";
    continueText = `View Upgraded ${pluralize(
      "Ticket",
      totalTicketQuantity,
      false
    )}`;
  }

  // Sets the continue button to disabled on payment form until cc info is valid
  useEffect(() => {
    if (isPaymentDetails) {
      setDisableContinue(
        !validateCreditCard(number.value, expDate.value, cvc.value)
      );
    }
  }, [
    isPaymentDetails,
    validateCreditCard,
    number,
    expDate,
    cvc,
    setDisableContinue,
  ]);

  const handlePaymentDetails = async () => {
    setIsTourUpgrade(false);
    setIsPaymentDetails(true);
    setIsCompleteUpgrade(false);
  };

  const handleCompleteUpgrade = async () => {
    setDisableContinue(true);
    setIsLoaded(false);

    const creditCardValid = validateCreditCard(
      number.value,
      expDate.value,
      cvc.value
    );

    if (creditCardValid) {
      updateDataLayer(getGTMData("checkout", order, eventItem, selectedTour));

      try {
        const { status, data } = await rebookOrder(
          order.orderId,
          selectedTour.id,
          {
            manualEntryCardNumber: number.value,
            expDate: expDate.value,
            cvc: cvc.value,
          },
          acmeRequestUuid
        );

        if (status === 200) {
          const resp = data as OrderWithRedirect;
          const rebookedOrder = resp.order;
          setIsLoaded(true);
          setIsCompleteUpgrade(true);
          setIsTourUpgrade(false);
          setIsPaymentDetails(false);
          setIsUpgradeError(false);
          setOrder(rebookedOrder);
          setEventItem(rebookedOrder.eventItems[0]);

          updateDataLayer(
            getGTMData("purchase", order, eventItem, selectedTour)
          );
        } else {
          setIsUpgradeError(true);
          const { message, error } = data as Error;
          console.log(message);
          console.log(error);
          title = "An error occurred!";
          // Refresh tours if order fails
          await refreshTours();
        }
      } catch (e) {
        setIsUpgradeError(true);
        console.log(e);
        title = "An error occurred!";
        // Refresh tours if order fails
        await refreshTours();
      } finally {
        // Reset the ACME request UUID once call to API complete
        setAcmeRequestUuid(uuidv4());
        setDisableContinue(false);
      }
    } else {
      setDisableContinue(false);
      setPaymentError("Invalid payment information.");
    }
  };

  // TODO: Move to a separate upgradeFooter component
  const handleContinue = async () => {
    if (isTourUpgrade) {
      setIsLoaded(false);
      const prevSelectedTourId = selectedTour.id;
      try {
        const refreshedTours = await refreshTours();

        if (prevSelectedTourId === refreshedTours[0].id) {
          // when showUpgrade, continue to isPaymentDetails
          handlePaymentDetails();
        } else {
          setErrorMessage(
            <span className="upgrade__error-text">
              Oops! This tour is no longer available.
            </span>
          );
          setIsUpgradeError(true);
        }

        updateDataLayer(
          getGTMData("addToCart", order, eventItem, selectedTour)
        );
      } catch (e) {
        console.log(e);
        setIsUpgradeError(true);
      } finally {
        setIsLoaded(true);
      }
    }
    if (isPaymentDetails) {
      // when isPaymentDetails, continue to isCompleteUpgrade
      handleCompleteUpgrade();
    }
    if (isCompleteUpgrade) {
      // when isCompleteUpgrade, continue to main page and setTourUpgradeComplete(true)
      setShowUpgrade(false);
      setTourUpgradeComplete(true);
    }
  };

  const handleBack = async () => {
    setShowUpgrade(false);
  };

  return (
    <div className="upgrade__content">
      {!isUpgradeError ? (
        <SpinnerSuspense isLoaded={Boolean(order)}>
          <TourUpgrade
            displayTourUpgrade={displayTourUpgrade}
            totalDue={totalDue}
            totalTicketQuantity={totalTicketQuantity}
            timeToTour={timeToTour}
          />
          <PaymentDetails
            displayPaymentDetails={displayPaymentDetails}
            creditCardFields={creditCardInputs}
            paymentError={paymentError}
            orderTotal={getOrderTotal(eventItem)}
            tourTotal={tourTotal}
            totalDue={totalDue}
            quantity={totalTicketQuantity}
          />
          <CompleteUpgrade
            displayCompleteUpgrade={displayCompleteUpgrade}
            totalTicketQuantity={totalTicketQuantity}
          />
          {/* TODO: Move to a separate upgradeFooter component */}
          <div className="upgrade__footer">
            <button
              className={
                !selectedTour
                  ? "upgrade__continue btn-barnes disabled"
                  : "upgrade__continue btn-barnes"
              }
              onClick={handleContinue}
              disabled={disableContinue}
            >
              {!isLoaded && !paymentError ? (
                <SpinnerSuspense
                  className="continue-spinner"
                  isLoaded={isLoaded}
                />
              ) : (
                continueText
              )}
            </button>
            <a
              type="button"
              onClick={handleBack}
              className="upgrade__no-thanks"
            >
              {backText}
            </a>
          </div>
        </SpinnerSuspense>
      ) : (
        <Fragment>
          <ErrorComponent message={errorMessage} />
          <div className="upgrade__footer">
            <button
              onClick={handleBack}
              className="upgrade__error-button btn-barnes"
            >
              Back to {pluralize("Ticket", totalTicketQuantity, false)}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};
