import { getTime, millisecondsToMinutes } from "date-fns";

import { EventItem } from "acme-ticketing-client";

/**
 * Returns the number of minutes from now to a given time. Used for display of the minutes until next tour.
 * @param {Date} now - Date.
 * @param {string} time - string with date and time.
 * @returns {number} minute difference between now and time.
 */
export const getTimeDiffInMin = (now: Date, time: string): number => {
  const startTime = getTime(new Date(time));
  const currTime = getTime(now);
  const minDifference = millisecondsToMinutes(startTime - currTime);

  return minDifference;
};

/**
 * Returns the number of tickets in an event item.
 * @param {EventItem} eventItem - EventItem.
 * @returns {number} total number of tickets.
 */
export const getTicketQuantity = (eventItem: EventItem): number => {
  const reducer = (accumulator, item) => {
    return accumulator + item["quantity"];
  };
  const quantity = eventItem.items.reduce(reducer, 0);
  return quantity;
};

/**
 * Strip query param from URL.
 * @param {string} name query param.
 * @param {string} url full URL including query. Use window.location.href to get this param.
 * @returns {string} query param passed in the url.
 * @see https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 */
export const getParameterByName = (name: string, url: string): string => {
  const strippedName = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${strippedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results || !results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Returns the total order cost
 * @param {EventItem} eventItem - EventItem.
 * @returns {number} total cost of all tickets.
 */
export const getOrderTotal = (eventItem: EventItem): number => {
  const reducer = (accumulator, item) => {
    return accumulator + parseFloat(item["discountedSubtotal"]);
  };
  const orderTotal = eventItem?.items.reduce(reducer, 0);

  return orderTotal;
};

/**
 * Returns the upgrade cost
 * @param {EventItem} eventItem - EventItem.
 * @param {number} price - cost for one tour ticket
 * @returns {number} total cost for upgrade.
 */
export const getUpgradeCost = (eventItem: EventItem, price: number): number => {
  const totalTicketQuantity = getTicketQuantity(eventItem);

  const tourTotal = totalTicketQuantity * price;
  return tourTotal - getOrderTotal(eventItem);
};
