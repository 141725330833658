import { useEffect, useState } from "react";
import qrCode from "qrcode";

const DEFAULT_WIDTH = 400;

/**
 * Custom hook to turn attribute into QR Data URI.
 * @param {string} qrContent - content to be converted to URI.
 * @returns {string} - Data URI containing a representation of the QR Code image
 */
export const useQR = (qrContent: string) => {
  // Create QR code on mount.
  const [qr, setQR] = useState(null as string);

  useEffect(() => {
    const getQR = async () => {
      const qr = qrContent
        ? await qrCode.toDataURL(qrContent, {
            width: DEFAULT_WIDTH,
            margin: 0,
            color: {
              dark: "#282828",
              light: "#fff",
            },
          } as qrCode.QRCodeToDataURLOptions)
        : null;
      setQR(qr);
    };
    getQR();
  }, [qrContent]);

  return qr;
};
