import { Description, Slider, SliderCard } from "../shared/slider";
import React, { useContext, useEffect, useState } from "react";

import { BannerCard } from "../shared/bannerCard";
import { OrderContext } from "../../contexts/orderContext";
import { formatSingleDate } from "../../util/formatting";
import { useQR } from "../../hooks/customHooks";

type SingleEventItemProps = {
  uuid: string;
  ticketTypeName: string;
};

const SingleEventItem: React.FC<SingleEventItemProps> = ({
  uuid,
  ticketTypeName,
}: SingleEventItemProps) => {
  const { eventItem } = useContext(OrderContext);
  const ticketQR = useQR(JSON.stringify({ uuid }));

  const descriptions: Description[] = [
    { value: eventItem?.eventName, modifier: "title" },
    { value: ticketTypeName, modifier: "sub-header" },
    {
      fieldName: "Date",
      value: formatSingleDate(eventItem?.eventDate),
      modifier: "detail",
    },
    {
      fieldName: "Time",
      value: formatSingleDate(eventItem?.eventDate, "h:MM TT"),
      modifier: "detail",
    },
  ];

  return <SliderCard qr={ticketQR} descriptions={descriptions} />;
};

export const EventItems: React.FC = () => {
  const { order, eventItem } = useContext(OrderContext);

  return (
    <div className="event-items">
      <BannerCard
        name={`Order # ${order.orderNumber}`}
        description={`
              <p>
                  Once in the galleries, you can explore the art with our digital guide <a class="a-brand-link a-brand-link--underline a-brand-link--space" href="https://barnesfoc.us" rel="noopener noreferrer" target="_blank"><span>Barnes Focus</span></a>.
              </p>
          `}
      />

      <Slider>
        {eventItem?.items.map((item) =>
          item.tickets.map((ticket) => (
            <SingleEventItem
              key={ticket.uuid}
              uuid={ticket.uuid}
              ticketTypeName={item.ticketTypeName || item.itemTypeName}
            />
          ))
        )}
      </Slider>
    </div>
  );
};
