import React, { Fragment, useContext, useEffect, useState } from "react";

import { DisplayContext } from "../contexts/displayContext";
import { Error } from "../components/shared/error";
import { EventItems } from "../components/order/eventItems";
import { Footer } from "../components/footer/footer";
import { OrderContext } from "../contexts/orderContext";
import { OrderWithRedirect } from "common/payloads";
import { SpinnerSuspense } from "../components/shared/spinner";
import { TitleBar } from "../components/titleBar";
import { ToursContext } from "../contexts/toursContext";
import { getOrder } from "../services/orderService";
import { getParameterByName } from "../util/helpers";

const Layout: React.FC = () => {
  const {
    setOrder,
    setEventItem,
    orderInformationReady,
    setOrderInformationReady,
  } = useContext(OrderContext);
  const { setTours, setSelectedTour } = useContext(ToursContext);
  const { setTourUpgradeComplete } = useContext(DisplayContext);
  const [isError, setIsError] = useState(false);
  const { showUpgrade } = useContext(DisplayContext);
  const [overflow, setOverflow] = useState("auto");

  const errorMessage = (
    <Fragment>
      <p className="general__error-text">
        We&apos;re having trouble accessing your order.
      </p>
      <p className="general__error-text">Please try again in a few minutes.</p>
    </Fragment>
  );

  useEffect(() => {
    showUpgrade ? setOverflow("hidden") : setOverflow("auto");
  }, [showUpgrade, setOverflow]);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        // Fetch order via encrypted "i" querystring
        const { status, data } = await getOrder(
          getParameterByName("i", window.location.href)
        );

        if (status === 200) {
          const { order, redirect, tours } = data as OrderWithRedirect;
          if (redirect) {
            window.location.href = redirect;

            // Otherwise set order.
            // This is to prevent FOUC.
          } else {
            setOrder(order);
            setTours(tours);
            setSelectedTour(tours[0]);
            const eventItem = order && order.eventItems && order.eventItems[0];
            setEventItem(eventItem);
            setTourUpgradeComplete(!eventItem.eventName.includes("Admission"));
            setOrderInformationReady(true);
          }
        } else {
          setIsError(true);
        }
      } catch {
        setIsError(true);
      }
    };

    fetchOrder();
  }, [
    setTours,
    setSelectedTour,
    setOrder,
    setEventItem,
    setIsError,
    setOrderInformationReady,
    setTourUpgradeComplete,
  ]);

  return (
    <div
      className="app"
      style={{
        overflow: `${overflow}`,
      }}
    >
      <TitleBar />
      <div className="app__content">
        {!isError ? (
          <SpinnerSuspense isLoaded={orderInformationReady}>
            {orderInformationReady && <EventItems />}
          </SpinnerSuspense>
        ) : (
          <Error message={errorMessage} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
