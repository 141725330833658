import React, { useEffect, useRef, useState } from "react";

import { DownArrow } from "../footer/icons";

type BannerHeaderProps = {
  imageWrapperHeight: number | void;
  header?: string;
  bottomText?: string;
};
export const BannerHeader: React.FC<BannerHeaderProps> = ({
  imageWrapperHeight,
  header,
  bottomText,
}: BannerHeaderProps) => {
  const ref = useRef<HTMLDivElement>();
  const [isResizeFont, setIsResizeFont] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Set is loaded after first render.
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // On mount, see if text fits in container, if not then setState to true.
  useEffect(() => {
    if (isLoaded) {
      // If text is bigger than it's current boundary
      const isResized =
        imageWrapperHeight !== null &&
        ref.current &&
        imageWrapperHeight < ref.current.offsetHeight &&
        isLoaded;

      setIsResizeFont(isResized);
    }
  }, [isLoaded, imageWrapperHeight]);

  let headerStyle: React.CSSProperties = {};
  if (isResizeFont) {
    headerStyle = {
      ...headerStyle,
      fontSize: "27.5px",
    };
  }

  return (
    <>
      <h2
        className="hero-banner__header"
        style={{
          ...headerStyle,
          opacity: isLoaded ? 1 : 0,
        }}
        ref={ref}
      >
        {header}
      </h2>
      {bottomText && (
        <div>
          <p
            className="hero-banner__bottomText"
            style={{
              ...headerStyle,
              opacity: isLoaded ? 1 : 0,
            }}
          >
            {bottomText}
          </p>
          <DownArrow className="bounce down-arrow" />
        </div>
      )}
    </>
  );
};
