import { HeroBanner } from "./heroBanner";
import React from "react";

type BannerCardProps = {
  name: string;
  src?: string;
  description?: string;
  isHTML?: boolean;
  className?: string;
  // imageOffset?: number;
  children?: any;
};

export const BannerCard: React.FC<BannerCardProps> = ({
  name,
  src = null,
  description,
  isHTML = true,
  className,
  children,
}: // imageOffset = 0,
BannerCardProps) => {
  let bannerCardClassName = "banner-card";

  if (className) {
    bannerCardClassName = `${bannerCardClassName} ${className}`;
  }

  return (
    <div className={bannerCardClassName}>
      <HeroBanner
        header={name}
        src={src}
        // imageOffset={imageOffset}
      />
      <div className="banner-card__text-wrapper">
        {/** Drop in text or HTML */}
        {Boolean(description || React.Children.count(children) === 0) &&
        isHTML ? (
          <div
            className="banner-card__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          <div className="banner-card__text">{description || children}</div>
        )}
      </div>
    </div>
  );
};
