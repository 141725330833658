import React, { createContext } from "react";

// State for the logic of which components are shown

export type DisplayType = {
  showUpgrade: boolean; // Tracks whether or not the tour upgrade details are shown
  setShowUpgrade: React.Dispatch<React.SetStateAction<boolean>>;
  tourUpgradeComplete: boolean;
  setTourUpgradeComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DisplayContext = createContext<DisplayType>({
  showUpgrade: false,
  setShowUpgrade: null,
  tourUpgradeComplete: false,
  setTourUpgradeComplete: null,
});
