import React, { useState } from "react";

import { OrderLink } from "../../common/payloads";
import { getOrderLink } from "../services/orderService";

export const SearchOrders = () => {
  const [orderId, setOrderId] = useState(null);
  const [orderLink, setOrderLink] = useState(null);
  // form where you enter order id
  // returns link to order

  const handleChange = (event) => {
    console.log(event.target.value);
    setOrderId(event.target.value);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const { status, data } = await getOrderLink(orderId);

    if (status === 200) {
      const { encryptedOrderId } = data as OrderLink;
      const orderLink = `/t?i=${encryptedOrderId}`;
      setOrderLink(orderLink);
    }
  };

  return (
    <div>
      Find an order!
      <form>
        <label htmlFor="order-id">Order ID:</label>
        <input id="order-id" type="text" onChange={handleChange} />
        <button type="button" onClick={handleClick}>
          Get order link!
        </button>
      </form>
      {orderLink && <a href={orderLink}>Order #{orderId}</a>}
    </div>
  );
};
