import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  OrderPayload,
  EventTimeObject,
  EventItem,
} from "acme-ticketing-client";
import TagManager from "react-gtm-module";

import { SearchOrders } from "./components/searchOrders";
import { Error } from "./components/shared/error";
import Layout from "./components/layout";
import { OrderContext } from "./contexts/orderContext";
import { ToursContext } from "./contexts/toursContext";
import { DisplayContext } from "./contexts/displayContext";

const tagManagerArgs = {
  gtmId: "GTM-M7646NF",
};
TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  const [order, setOrder] = useState<OrderPayload | null>(null);
  const [eventItem, setEventItem] = useState<EventItem | null>(null);
  const [orderInformationReady, setOrderInformationReady] = useState(false);
  const [tours, setTours] = useState<EventTimeObject[]>([]);
  const [selectedTour, setSelectedTour] = useState<EventTimeObject | null>(
    null
  );
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [tourUpgradeComplete, setTourUpgradeComplete] = useState(false);

  return (
    <BrowserRouter>
      <OrderContext.Provider
        value={{
          order,
          setOrder,
          eventItem,
          setEventItem,
          orderInformationReady,
          setOrderInformationReady,
        }}
      >
        <ToursContext.Provider
          value={{
            tours,
            setTours,
            selectedTour,
            setSelectedTour,
          }}
        >
          <DisplayContext.Provider
            value={{
              showUpgrade,
              setShowUpgrade,
              tourUpgradeComplete,
              setTourUpgradeComplete,
            }}
          >
            <Switch>
              {process.env.REACT_APP_ENV_TYPE !== "production" && (
                <Route exact path="/search-orders" component={SearchOrders} />
              )}
              <Route exact path="/error" component={Error} />
              <Route>
                <Layout />
              </Route>
            </Switch>
          </DisplayContext.Provider>
        </ToursContext.Provider>
      </OrderContext.Provider>
    </BrowserRouter>
  );
};

export default App;
