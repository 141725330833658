import cardValidator from "card-validator";

/**
 * @param {string} number - credit card number to be validated
 * @returns {boolean} - whether or not the given credit card number is valid
 */
export const validateNumber = (number: string): boolean => {
  return cardValidator.number(number).isValid;
};

/**
 * @param {string} expirationDate - expiration date to be validated
 * @returns {boolean} - whether or not the given date is valid
 */
export const validateExpirationDate = (expirationDate: string): boolean => {
  return cardValidator.expirationDate(expirationDate).isValid;
};

/**
 * @param {string} number - credit card number associated with the cvc
 * @param {string} cvc - cvc number to be validated
 * @returns {boolean} - whether or not the given cvc is valid
 */
export const validateCVC = ({
  number,
  cvc,
}: {
  number: string;
  cvc: string;
}): boolean => {
  const card = cardValidator.number(number);
  const cvcLength = card.card && card.card.type === "american-express" ? 4 : 3;
  return cardValidator.cvv(cvc, cvcLength).isValid;
};

/**
 * @param {string} number - credit card number to be validated
 * @param {string} expDate - expiration date associated with the credit card
 * @param {string} cvc - cvc associated with the credit card
 * @returns {boolean} - whether or not the credit card, exp date, and cvc are all valid
 */
export const validateCreditCard = (
  number: string,
  expDate: string,
  cvc: string
): boolean => {
  const isCCNumberValid = number && validateNumber(number);
  const isExpDateValid = expDate && validateExpirationDate(expDate);
  const isCVCValid = cvc && validateCVC({ number, cvc });

  return isCCNumberValid && isExpDateValid && isCVCValid;
};
