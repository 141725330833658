import { EventTimeObject } from "acme-ticketing-client";
import { executePayloadRequest, Payload } from "./httpService";

export const getTours = async (
  startTime: string,
  ticketQuantity: number,
  i: string
): Promise<Payload<EventTimeObject[]>> => {
  return executePayloadRequest<EventTimeObject[]>({
    url: `/api/tours`,
    method: "POST",
    data: { startTime: startTime, ticketQuantity: ticketQuantity, i: i },
  });
};
