import React from "react";

import { CreditCardInputFields } from "./hooks/useCreditCardForm";

type PaymentFormProps = {
  creditCardFields: CreditCardInputFields;
  paymentError?: string;
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
  creditCardFields,
  paymentError,
}: PaymentFormProps) => {
  const { number, expDate, cvc } = creditCardFields;

  return (
    <form className="payment-method">
      <div className="payment-form__form">
        {/* Credit Card Number Input */}
        <div className="payment-form__body first-row">
          <div className={`payment-form number`}>
            {!number.isValid && (
              <span className="payment-form__warning">Invalid card number</span>
            )}
            {number.isValid && (
              <h3 className="payment-form__label">CARD NUMBER</h3>
            )}

            <input
              className={
                number.isValid || !paymentError
                  ? "payment-form__input"
                  : "payment-form__input payment-form__input--error"
              }
              type="text"
              value={number.maskedValue}
              onChange={number.onChange}
              onBlur={number.onBlur}
              onFocus={number.onFocus}
              required
              placeholder="Card Number"
              maxLength={19}
            />
          </div>
        </div>

        {/* Expiration Date Input */}
        <div className="payment-form__body second-row">
          <div className={`payment-form expDate`}>
            {!expDate.isValid && (
              <span className="payment-form__warning">
                Invalid expiration date
              </span>
            )}
            {expDate.isValid && (
              <h3 className="payment-form__label">EXPIRATION DATE</h3>
            )}

            <input
              className={
                expDate.isValid || !paymentError
                  ? "payment-form__input"
                  : "payment-form__input payment-form__input--error"
              }
              type="text"
              value={expDate.maskedValue}
              onChange={expDate.onChange}
              onBlur={expDate.onBlur}
              required
              placeholder="MM/YY"
              inputMode="numeric"
              maxLength={5}
            />
          </div>

          {/* CVC Input */}
          <div className={`payment-form cvc`}>
            {!cvc.isValid && (
              <span className="payment-form__warning">
                Invalid security code
              </span>
            )}
            {cvc.isValid && <h3 className="payment-form__label">CVC</h3>}

            <input
              className={
                cvc.isValid || !paymentError
                  ? "payment-form__input"
                  : "payment-form__input payment-form__input--error"
              }
              type="text"
              value={cvc.value}
              onChange={cvc.onChange}
              onBlur={cvc.onBlur}
              required
              placeholder="CVC"
              maxLength={4}
              inputMode="numeric"
              pattern="[0-9]{3,4}"
            />
          </div>
        </div>
      </div>
      {(number.isValid && expDate.isValid && cvc.isValid) ||
        (!!paymentError && (
          <span className="payment-form__warning">{paymentError}</span>
        ))}
    </form>
  );
};
