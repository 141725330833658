import React, { Fragment, useContext } from "react";

import { CreditCardInputFields } from "../hooks/useCreditCardForm";
import { DownArrow } from "../../footer/icons";
import { OrderContext } from "../../../contexts/orderContext";
import { PaymentForm } from "../paymentForm";
import { TicketCard } from "../../order/ticketCard";
import { ToursContext } from "../../../contexts/toursContext";
import { UpgradeHeader } from "../upgradeHeader";

type PaymentDetailsProps = {
  unitPrice?: string;
  displayPaymentDetails: string;
  creditCardFields: CreditCardInputFields;
  paymentError?: string;
  orderTotal: number;
  tourTotal: number;
  totalDue: number;
  quantity: number;
};

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  unitPrice,
  displayPaymentDetails,
  creditCardFields,
  paymentError,
  orderTotal,
  tourTotal,
  totalDue,
  quantity,
}: PaymentDetailsProps) => {
  const { eventItem } = useContext(OrderContext);
  const { selectedTour } = useContext(ToursContext);

  const title = "Confirm Upgrade";

  return (
    <Fragment>
      <div
        className="payment-details"
        style={{
          display: `${displayPaymentDetails}`, // set display to none when !isPaymentDetails
        }}
      >
        <UpgradeHeader title={title} />
        <h4 className="upgrade__subtitle">Payment Information</h4>
        <div className="payment">
          <PaymentForm
            creditCardFields={creditCardFields}
            paymentError={paymentError}
          ></PaymentForm>
        </div>
        <div className="details">
          <h4 className="upgrade__subtitle">Upgrade Details</h4>
          <div className="details__ticket-upgrade">
            {/** Post-upgrade */}
            {selectedTour && (
              <>
                {/** Pre-upgrade */}
                <TicketCard
                  eventName={eventItem?.eventName}
                  eventDate={eventItem?.eventDate}
                  includeTime={true}
                  ticketItems={eventItem?.items.map(
                    ({
                      quantity,
                      itemTypeName: displayName,
                      discountedUnitPrice: unitPrice,
                    }) => ({
                      quantity,
                      displayName,
                      unitPrice,
                    })
                  )}
                  preUpgrade={true}
                  isCheckbox={false}
                  includeDetails={false}
                  total={orderTotal}
                />
                <div className="arrow-section">
                  <DownArrow className="down-arrow" />
                </div>
                {/** Post-upgrade */}
                <TicketCard
                  eventName={selectedTour?.event.name}
                  eventDate={selectedTour?.startTime}
                  includeTime={true}
                  ticketItems={[
                    {
                      displayName: "Tour",
                      quantity: quantity,
                      unitPrice,
                    },
                  ]}
                  isCheckbox={false}
                  includeDetails={false}
                  total={tourTotal}
                />
                <div className="summary-total">
                  <h4>Total Tour Cost: &nbsp;&nbsp;&nbsp; ${tourTotal}.00</h4>
                  <h4>Credit Applied: &nbsp;– ${orderTotal}.00</h4>
                  <hr />
                  <h3>Balance due: ${totalDue}.00</h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
