import React, { useContext, useState } from "react";

import { EventTimeObject } from "acme-ticketing-client";
import { ToursContext } from "../../contexts/toursContext";
import { formatSingleDate } from "../../util/formatting";

export type TicketItem = {
  displayName: string;
  quantity: number;
  unitPrice: string;
};

type TicketProps = {
  eventName?: string;
  eventDate?: Date | string;
  eventDescription?: string;
  tour?: EventTimeObject;
  includeTime?: boolean;
  isConversion?: boolean;
  checkInDate?: string;
  ticketItems?: TicketItem[];
  preUpgrade?: boolean;
  isCheckbox: boolean;
  includeDetails?: boolean;
  total?: number;
};

export const TicketCard: React.FC<TicketProps> = ({
  eventName,
  eventDate,
  eventDescription,
  tour,
  includeTime = false,
  isConversion = false,
  checkInDate,
  ticketItems,
  preUpgrade = false,
  isCheckbox = false,
  includeDetails = false,
  total,
}: TicketProps) => {
  const { setSelectedTour } = useContext(ToursContext);
  const [isChecked, setIsChecked] = useState(true);
  // See below comments for reference on showDetails feature
  const [showDetails, setShowDetails] = useState(true);
  let height = "12vh";
  let ticketCardClassName = "ticket-card";

  if (preUpgrade) {
    ticketCardClassName = `${ticketCardClassName} ticket-card--preupgrade`;
  }

  if (includeDetails) {
    height = "40vh";
  }

  const handleChange = () => {
    setIsChecked(!isChecked);
    isChecked ? setSelectedTour(tour) : setSelectedTour(null);
  };

  // If uncommented, will allow for showing event details on click. Removed temporarily to show details by default.
  // const handleShowDetails = async () => {
  //   setShowDetails(!showDetails);
  // };

  return (
    <div
      className={ticketCardClassName}
      style={{
        height: `${height}`,
      }}
    >
      <div className="wrapper">
        {isCheckbox && (
          <label className="ticket-card__check">
            <input type="checkbox" onChange={handleChange} />
            <span className="checkmark" />
          </label>
        )}
        <div className="column1">
          {eventName && (
            <h3 className="ticket-card__event-header">{eventName}</h3>
          )}
          {eventDate && (
            <p className="ticket-card__event-date">
              {formatSingleDate(eventDate)}
            </p>
          )}
          {eventDate && includeTime && (
            <p className="ticket-card__event-date">
              {formatSingleDate(eventDate, "h:MM TT")}
            </p>
          )}
        </div>

        <div className="column2">
          {ticketItems &&
            ticketItems.map((orderItem, i) => (
              <p className="ticket-card__item" key={i}>
                {orderItem.quantity} {orderItem.displayName} x $
                {orderItem.unitPrice || "45.00"}
                {/* get tour unit price from acme */}
              </p>
            ))}
          <hr></hr>
          {checkInDate && (
            <span className="ticket-card__event-detail ticket-card__event-detail--expiration-date">
              Redeemed on: {formatSingleDate(checkInDate)}
            </span>
          )}
          {/* If uncommented, will allow for showing event details on click. Removed temporarily to show details by default. */}
          {/* {includeDetails && (
            <a
              type="button"
              onClick={handleShowDetails}
              className="ticket-card__view-details"
            >
              View Details
            </a>
          )} */}
        </div>
      </div>
      {showDetails && eventDescription && (
        <div
          className="dropdown"
          dangerouslySetInnerHTML={{ __html: eventDescription }}
        ></div>
      )}
    </div>
  );
};
