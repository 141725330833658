import React, { Fragment, useContext } from "react";

import { Close } from "../footer/icons";
import { DisplayContext } from "../../contexts/displayContext";

type UpgradeHeaderProps = {
  title?: string;
  subtitle?: string;
};

export const UpgradeHeader: React.FC<UpgradeHeaderProps> = ({
  title,
  subtitle,
}: UpgradeHeaderProps) => {
  const { setShowUpgrade, setTourUpgradeComplete } = useContext(DisplayContext);
  const handleBack = async () => {
    setShowUpgrade(false);
  };

  return (
    <Fragment>
      <div className="upgrade__close">
        <Close type="button" onClick={handleBack} />
      </div>
      <div className="upgrade__header">
        {title && <h1 className="upgrade__title">{title}</h1>}
        {subtitle && <h4 className="upgrade__subtitle">{subtitle}</h4>}
      </div>
    </Fragment>
  );
};
