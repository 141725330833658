import React, { Fragment, useContext } from "react";

import { TicketCard } from "../../order/ticketCard";
import { ToursContext } from "../../../contexts/toursContext";
import { UpgradeHeader } from "../upgradeHeader";
import { formatMinutes } from "../../../util/formatting";

type TourUpgradeProps = {
  displayTourUpgrade: string;
  totalDue: number;
  totalTicketQuantity: number;
  timeToTour: number;
};
export const TourUpgrade: React.FC<TourUpgradeProps> = ({
  displayTourUpgrade,
  totalDue,
  totalTicketQuantity,
  timeToTour,
}: TourUpgradeProps) => {
  const { tours } = useContext(ToursContext);

  const title = "Make the most of your visit to the Barnes";
  const subtitle = `For an additional $${totalDue}, upgrade your ${
    totalTicketQuantity === 1 ? "ticket" : "group"
  } to include this tour, starting in ${formatMinutes(timeToTour)}!`;

  return (
    <Fragment>
      <div
        className="tour-upgrade"
        style={{
          display: `${displayTourUpgrade}`,
        }}
      >
        <UpgradeHeader title={title} subtitle={subtitle} />
        <div className="tour-upgrade__wrapper">
          {/* {tours.map((tour) => (
        <TicketCard
          key={tour.id}
          eventName={tour.event.name}
          eventDate={tour.startTime}
          eventDescription={tour.event.description}
          tour={tour}
          includeTime={true}
          isCheckbox={true}
          includeDetails={true}
        />
      ))} */}
          <TicketCard
            key={tours[0].id}
            eventName={tours[0].event.name}
            eventDate={tours[0].startTime}
            eventDescription={tours[0].event.description}
            tour={tours[0]}
            includeTime={true}
            isCheckbox={false}
            includeDetails={true}
          />
        </div>
      </div>
    </Fragment>
  );
};
