import TagManager from "react-gtm-module";

const updateDataLayer = (data) => {
  TagManager.dataLayer({ dataLayer: { ecommerce: null } }); // Clear previous ecommerce object
  TagManager.dataLayer({ dataLayer: data });
};

export const useDataLayer = () => {
  return updateDataLayer;
};
