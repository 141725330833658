import React from "react";
import { ReactComponent as BarnesLogo } from "./shared/icons/barnesLogo.svg";

export const TitleBar: React.FC = () => {
  const today = new Date();
  const isCentennial = true;
  // const isCentennial = today.getFullYear() === 2022;

  return (
    <div className="title-bar">
      <a
        href="https://barnesfoundation.org/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <BarnesLogo className="title-bar__logo" />
      </a>
    </div>
  );
};
