import dateformat from "dateformat";
import { minutesToHours } from "date-fns";
import pluralize from "pluralize";

/**
 * Format a single date, the default format is 'mmmm d, yyyy'.
 * @param {Date | string} date - date to be formatted.
 * @param {string} [format] - optional override for default format.
 * @returns {string} - formatted date
 */
export const formatSingleDate = (
  date: Date | string,
  format?: string
): string => dateformat(date, format || "mmmm d, yyyy");

/**
 * Formats number of minutes into human readable string.
 * @param {number} minutes - Number of minutes.
 * @returns {string} formatted string used in tour upsell offer.
 */
export const formatMinutes = (minutes: number): string => {
  const hours = minutesToHours(minutes);

  if (minutes > 60) {
    return `${pluralize("hour", hours, true)}`;
  } else {
    return `${pluralize("minute", minutes, true)}`;
  }
};
