import React, { Fragment } from "react";

import { ReactComponent as SadFace } from "../../images/sad-face.svg";

type ErrorProps = {
  message?: string | JSX.Element;
};

export const Error: React.FC<ErrorProps> = ({ message }: ErrorProps) => {
  const defaultMessage = (
    <Fragment>
      <p className="upgrade__error-text">
        Oops! We&apos;re having trouble completing your request.
      </p>
      <p className="upgrade__error-text">
        Please contact <a href="tel:2152787200">215-278-7200</a> for assistance.
      </p>
    </Fragment>
  );

  return (
    <div className="error-wrapper">
      <div className="upgrade__error">
        <SadFace className="upgrade__sad-face" />
        {!!message ? message : defaultMessage}
      </div>
    </div>
  );
};
