import React, { useState } from "react";

import {
  validateNumber,
  validateExpirationDate,
  validateCVC,
  validateCreditCard,
} from "../../../util/validation";
import { maskCardNumber } from "../../../util/masking";

export type InputField = {
  isValid: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  maskedValue?: string;
  onBlur: () => void;
  onChange: (event: any) => void;
  onFocus?: () => void;
  validate: (any) => boolean;
};

export type CreditCardInputFields = {
  number: InputField;
  expDate: InputField;
  cvc: InputField;
  validateCreditCard: (number: string, expDate: string, cvc: string) => boolean;
};

// Custom hook for generating input fields with validation for a credit card payment form
export const useCreditCardForm = (): CreditCardInputFields => {
  // Credit Card Number
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [number, setNumber] = useState("");
  const [maskedNumber, setMaskedNumber] = useState("");
  const onCreditCardBlur = () => {
    const isValid = validateNumber(number);
    setIsNumberValid(isValid);
    setMaskedNumber(maskCardNumber(number));
  };
  const onCreditCardChange = (event) => {
    const value = event.target.value.replace(/\s/g, "");
    setNumber(value);
    setMaskedNumber(value.replace(/([0-9]{4})/g, "$& ").trim());
  };
  const onCreditCardFocus = () => {
    setMaskedNumber(number.replace(/([0-9]{4})/g, "$& ").trim());
  };

  // Expiration Date
  const [isExpDateValid, setIsExpDateValid] = useState(true);
  const [expDate, setExpDate] = useState("");
  const [maskedExpDate, setMaskedExpDate] = useState("");
  const onExpDateBlur = () => {
    const isValid = validateExpirationDate(expDate);
    setIsExpDateValid(isValid);
    const value = expDate.replace("/", "");
    setMaskedExpDate(value.replace(/^([0-9]{2})/g, "$&/"));
  };
  const onExpDateChange = (event) => {
    const value = event.target.value;
    setExpDate(value);
    setMaskedExpDate(value);
  };

  // CVC
  const [isCVCValid, setIsCVCValid] = useState(true);
  const [cvc, setCVC] = useState("");
  const onCVCBlur = () => {
    const isValid = validateCVC({ number, cvc });
    setIsCVCValid(isValid);
  };

  return {
    number: {
      isValid: isNumberValid,
      value: number,
      setValue: setNumber,
      maskedValue: maskedNumber,
      onBlur: onCreditCardBlur,
      onChange: onCreditCardChange,
      onFocus: onCreditCardFocus,
      validate: validateNumber,
    },
    expDate: {
      isValid: isExpDateValid,
      value: expDate,
      setValue: setExpDate,
      maskedValue: maskedExpDate,
      onBlur: onExpDateBlur,
      onChange: onExpDateChange,
      validate: validateExpirationDate,
    },
    cvc: {
      isValid: isCVCValid,
      value: cvc,
      setValue: setCVC,
      onBlur: onCVCBlur,
      onChange: (event) => setCVC(event.target.value),
      validate: validateCVC,
    },
    validateCreditCard: validateCreditCard,
  };
};
