import { useEffect, useState } from "react";

// Hook to get current time
// adapted from https://javascript.plainenglish.io/usetime-react-hook-f57979338de
export const useTime = (refreshCycle = 100) => {
  // Returns the current time
  // and queues re-renders every `refreshCycle` milliseconds (default: 100ms)

  const [now, setNow] = useState(getTime());

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setNow]);

  return now;
};

export const getTime = () => {
  const now = new Date();
  now.setSeconds(0, 0);
  return now;
};
