import React, { createContext } from "react";
import { OrderPayload, EventItem } from "acme-ticketing-client";

// Order information for tickets

export type OrderType = {
  order: OrderPayload | null; // Order response from ACME
  setOrder: React.Dispatch<React.SetStateAction<OrderPayload | null>>;
  eventItem: EventItem | null; // Details for the event from the order
  setEventItem: React.Dispatch<React.SetStateAction<EventItem | null>>;
  orderInformationReady: boolean;
  setOrderInformationReady: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrderContext = createContext<OrderType>({
  order: null,
  setOrder: null,
  eventItem: null,
  setEventItem: null,
  orderInformationReady: false,
  setOrderInformationReady: null,
});
